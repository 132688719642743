import { useCallback, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import {
  Formik,
  Form as FormikForm,
  Field,
  useFormikContext
} from 'formik'

import _get from 'lodash/get'
import _pick from 'lodash/pick'
import _ from 'lodash'

import TextField from 'components/Fields/Text'
import Button from 'components/Button'
import ErrorMessage from 'components/ErrorMessage'
import Message from 'components/Message'

import useTranslate from 'hooks/useTranslate'

import vars from 'config/theme/vars'

const FormWrapper = styled.div`
  width: 100%;
  max-width: ${vars.formMaxWidth};
`

const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const SubmitButton = styled(Button)`
  width: auto;
  ${props => props.disabled && `
    background-color: #dfdfdf;
    pointer-events: none;
  `}
`

const CentredErrorMessage = styled(ErrorMessage)`
  text-align: center;
  padding-bottom: 20px;
`

const SubmitWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const DependantField = (props) => {
  const {
    values,
    setFieldValue
  } = useFormikContext()

  useEffect(() => {
    if (
      values[props.dependsOn]
    ) {
      setFieldValue(props.name, props.computeValue())
    }
  }, [values[props.dependsOn], setFieldValue, props.name])

  return (
    <Field {...props} />
  )
}

const Form = (props) => {
  const {
    fieldSchema,
    validationSchema,
    initialValues: initialValuesProp,
    onSubmit,
    children,
    buttonLabel,
    parseError,
    noSubmitButton,
    successMessage,
    resetOnSuccess,
    enableReinitialize,
    validateOnChange,
    validate,
    buttonDisable
  } = props

  const [succeeded, setSucceeded] = React.useState(false)
  const translate = useTranslate()
  const [values, setValues] = React.useState(null)

  const initialValues = useMemo(() => {
    const initialValuesFromSchema = fieldSchema.reduce((acc, fieldConfig) => {
      acc[fieldConfig.name] = fieldConfig.initialValue || ''
      return acc
    }, {})
    const allInitialValues = {
      ...initialValuesFromSchema,
      ...initialValuesProp
    }
    return allInitialValues
  }, [fieldSchema])

  const renderForm = useCallback(
    (formProps) => {
      const { values, errors, isSubmitting } = formProps
      const submitError = _get(errors, 'submit')

      return (
        <FormikForm enableReinitialize={enableReinitialize}>
          <FieldsWrapper>
            {fieldSchema.map((fieldConfig) => {
              const {
                autoComplete,
                component,
                name,
                label,
                type,
                helpText,
                isVisible,
                legend,
                hint,
                dependsOn
              } = fieldConfig

              const visible = isVisible ? isVisible(values) : true

              const fieldProps = {
                autoComplete: autoComplete || 'off',
                component: component || TextField,
                name,
                label: _.isObject(label) ? label : translate(label),
                legend: translate(legend),
                helpText: translate(helpText),
                type: type || 'text',
                value: values[name],
                hint,
                ..._pick(fieldConfig, [
                  'inlineRight',
                  'icon',
                  'options',
                  'readOnly',
                  'width',
                  'rows',
                  'autoFocus',
                  'hidePlaceholder',
                  'disabled',
                  'required',
                  'onClick',
                  'hideLabel',
                  'placeholder',
                  'allowNullValue',
                  'labelLink',
                  'legendLink',
                  'keyQuestion',
                  'headingTag',
                  'id',
                  'helpId',
                  'dependsOn',
                  'computeValue',
                  'groupOptions',
                  'groupAttribute',
                  'parent',
                  'parentLabel',
                  'parentValue'
                ])
              }
              return visible && (dependsOn ? <DependantField key={name} {...fieldProps} /> : <Field key={name} {...fieldProps} />)
            })}
          </FieldsWrapper>
          {submitError ? <CentredErrorMessage>
            {submitError}
          </CentredErrorMessage>
            : null}
          {!noSubmitButton && (
            <SubmitWrapper>
              <SubmitButton
                type='submit'
                buttonType='primary'
                fullWidth
                isLoading={isSubmitting}
                disabled={buttonDisable}
              >
                {translate(buttonLabel)}
              </SubmitButton>
            </SubmitWrapper>
          )}
        </FormikForm>
      )
    },
    [fieldSchema, buttonLabel]
  )

  const handleSubmit = useCallback(
    (values, { setErrors, setSubmitting, resetForm }) => {
      return Promise.resolve()
        .then(() => {
          const submitPayload = validationSchema
            ? validationSchema.cast(values)
            : values
          setValues(values)
          return onSubmit(submitPayload)
        })
        .then(() => {
          setSucceeded(true)
          if (resetOnSuccess) resetForm()
        })
        .catch((error) => {
          if (error) {
            let parsed = parseError ? parseError(error) : 'ERROR_GENERIC'
            if (parsed && parsed.message) parsed = parsed.message

            setErrors({
              submit: (typeof parsed === 'string')
                ? translate(parsed)
                : parsed({ translate })
            })
            setSucceeded(false)
          }
        })
        .then(() => {
          setSubmitting(false)
        })
    },
    [onSubmit, parseError, validationSchema]
  )

  const formikProps = {
    onSubmit: handleSubmit,
    validateOnChange,
    validate,
    initialValues,
    validationSchema,
    enableReinitialize
  }

  const successMessageEl = React.useMemo(() => {
    if (succeeded && successMessage) {
      const message =
        typeof successMessage === 'function'
          ? successMessage(values) 
          : successMessage
      return <Message text={message} type={'success'} />
    }
    return null
  }, [succeeded, successMessage, values])

  return (
    <FormWrapper>
      <Formik {...formikProps}>{renderForm}</Formik>
      {successMessageEl}
      {children}
    </FormWrapper>
  )
}

export default Form
